import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
// React 组件懒加载
const Faq  = React.lazy(() => import('./page/faq'));
const Otc  = React.lazy(() => import('./page/otc'));
const Home  = React.lazy(() => import('./page/home'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/faq"  element={<Faq/>} />
              <Route path="/otc"  element={<Otc/>} />
              <Route path="/" element={<Home/>} />
              <Route path="/home" element={<Navigate to="/"  />} />
              <Route path="*" element={<Navigate to="/" /> }/>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

